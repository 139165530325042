var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"layoutSidenav_nav"}},[_c('nav',{staticClass:"sb-sidenav accordion sb-sidenav-light",staticStyle:{"box-shadow":"0 4px 8px rgba(0, 0, 0, 0.2)"},attrs:{"id":"sidenavAccordion"}},[_c('div',{staticClass:"sb-sidenav-menu"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"sb-sidenav-menu-heading"},[_vm._v("Menu")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'dashboard',
          },attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-tachometer-alt"}),_c('span',{staticClass:"m-2"},[_vm._v("Dashboard")])])]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'wbs',
          },attrs:{"to":"/wbs"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-file"}),_c('span',{staticClass:"m-2"},[_vm._v("Wbs")])])]),_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'dumas',
          },attrs:{"to":"/dumas"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-file-alt"}),_c('span',{staticClass:"m-2"},[_vm._v("Dumas")])])]),(_vm.userLevel == 'admin')?_c('router-link',{staticClass:"nav-link",class:{
            ' active': _vm.link == 'pengguna',
          },attrs:{"to":"/pengguna"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-file-alt"}),_c('span',{staticClass:"m-2"},[_vm._v("Pengguna")])])]):_vm._e()],1)]),_c('div',{staticClass:"sb-sidenav-footer"},[_c('div',{staticClass:"small"},[_vm._v("Login as :")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.userEmail)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }