<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="row">
          <div :class="userLevel == 'admin' ? 'col-md-8' : 'col-md-12'">
            <div class="card mb-4 mt-4">
              <div class="card-header bg-white">Detail Laporan</div>
              <div class="card-body">
                <div class="row">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td style="width: 180px" scope="row">Kode Laporan</td>
                        <td style="width: 5px">:</td>
                        <td>
                          <span v-if="detail.kode_laporan">
                            {{ detail.kode_laporan }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Tanggal</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.tanggal">
                            {{ detail.tanggal }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Jam</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.jam">
                            {{ detail.jam }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" v-if="userLevel == 'admin'">
            <div class="card mb-4 mt-4">
              <div class="card-body">
                <div class="form-group">
                  <label>Level IRBAN</label>
                  <select
                    name="level"
                    class="form-select"
                    v-model="detail.level_irban"
                    v-on:change="getIrban($event)"
                  >
                    <option value="">Pilih Level</option>
                    <option value="level_1">Level 1</option>
                    <option value="level_2">Level 2</option>
                    <option value="level_3">Level 3</option>
                    <option value="level_4">Level 4</option>
                    <option value="level_5">Level 5</option>
                    <option value="level_6">Level 6</option>
                    <option value="level_7">Level 7</option>
                    <option value="level_8">Level 8</option>
                  </select>
                </div>
                <div
                  class="form-group mt-2"
                  v-if="detail.level_irban || detail.irban"
                >
                  <label>IRBAN</label>
                  <select
                    name="level"
                    class="form-select"
                    v-model="detail.irban"
                  >
                    <option
                      v-for="irban in listIrban"
                      :key="irban.nomor_identitas"
                      :value="irban.nomor_identitas"
                    >
                      {{ irban.nama_lengkap }}
                    </option>
                  </select>
                </div>
                <div class="d-grid gap-2 mt-3" v-if="detail.irban">
                  <button
                    v-if="detail.status_laporan == '0'"
                    class="btn btn-sm btn-warning float-end"
                    v-on:click="prosesLaporan(detail.id_laporan, 1)"
                  >
                    <i
                      v-if="loading"
                      class="fas fa-circle-notch fa-spin"
                      style="margin-right: 5px"
                    ></i>
                    Verifikasi
                  </button>
                  <button
                    v-if="detail.status_laporan == '1'"
                    class="btn btn-sm btn-info float-end"
                    v-on:click="prosesLaporan(detail.id_laporan, 2)"
                  >
                    <i
                      v-if="loading"
                      class="fas fa-circle-notch fa-spin"
                      style="margin-right: 5px"
                    ></i>
                    Proses
                  </button>
                  <button
                    v-if="detail.status_laporan == '2'"
                    class="btn btn-sm btn-success float-end"
                    v-on:click="prosesLaporan(detail.id_laporan, 3)"
                  >
                    <i
                      v-if="loading"
                      class="fas fa-circle-notch fa-spin"
                      style="margin-right: 5px"
                    ></i>
                    Selesai
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mb-4">
              <div class="card-header bg-white">Pelapor</div>
              <div class="card-body">
                <div class="row">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td scope="row">Nama Pelapor</td>
                        <td style="width: 5px">:</td>
                        <td>
                          <span v-if="detail.nama_pelapor">
                            {{ detail.nama_pelapor }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">NIK</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.nik_pelapor">
                            {{ detail.nik_pelapor }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Pekerjaan</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.pekerjaan">
                            {{ detail.pekerjaan }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Jabatan</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.jabatan">
                            {{ detail.jabatan }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Telp/HP</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.no_telp">
                            {{ detail.no_telp }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Alamat</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.alamat">
                            {{ detail.alamat }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Foto KTP</td>
                        <td>:</td>
                        <td>
                          <a
                            :href="detail.foto_ktp"
                            class="btn btn-info btn-sm"
                            target="_blank"
                          >
                            Lihat KTP</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mb-4">
              <div class="card-header bg-white">Terlapor</div>
              <div class="card-body">
                <div class="row">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td scope="row">Nama Terlapor</td>
                        <td style="width: 5px">:</td>
                        <td>
                          <span v-if="detail.nama_terlapor">
                            {{ detail.nama_terlapor }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Institusi</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.institusi">
                            {{ detail.institusi }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Pekerjaan</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.pekerjaan_terlapor">
                            {{ detail.pekerjaan_terlapor }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Jabatan</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.jabatan_terlapor">
                            {{ detail.jabatan_terlapor }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Alamat</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.alamat_terlapor">
                            {{ detail.alamat_terlapor }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Aduan</td>
                        <td>:</td>
                        <td>
                          <span v-if="detail.isi_aduan">
                            {{ detail.isi_aduan }}
                          </span>
                          <span v-else class="skeleton-loader"></span>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Bukti Aduan</td>
                        <td>:</td>
                        <td>
                          <a
                            :href="detail.bukti_kejadian"
                            class="btn btn-info btn-sm"
                            target="_blank"
                          >
                            Lihat Bukti Aduan</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card mb-4">
              <div class="card-header bg-white">Lokasi</div>
              <div class="card-body">
                <div class="row">
                  <div v-if="loading" class="skeleton-screen"></div>
                  <div
                    id="map"
                    style="height: 400px"
                    @load="contentLoaded"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import L from "leaflet";
export default {
  name: "IndexPage",
  data() {
    return {
      detail: {
        id_laporan: "",
        kode_laporan: "",
        jenis_pelanggaran: "",
        tanggal: "",
        jam: "",
        nik_pelapor: "",
        institusi: "",
        nama_terlapor: "",
        pekerjaan_terlapor: "",
        jabatan_terlapor: "",
        alamat_terlapor: "",
        lng: "",
        lat: "",
        isi_aduan: "",
        bukti_kejadian: "",
        status_laporan: "",
        id_identitas_pelapor: "",
        jenis_pelapor: "",
        nip_pelapor: "",
        nama_pelapor: "",
        alamat: "",
        pekerjaan: "",
        jabatan: "",
        no_telp: "",
        foto_ktp: "",
        irban: "",
        level_irban: "",
      },
      listIrban: [],
      loading: false,
      kode_laporan: "",
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("wbsData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
    }
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    this.kode_laporan = id;
    this.fetchData();
  },
  methods: {
    contentLoaded() {
      this.loading = false;
    },
    fetchData() {
      axios
        .get("/api/lapor/detail/" + this.kode_laporan)
        .then((response) => {
          this.rows = response.data.data;
          for (let key in this.detail) {
            this.detail[key] = response.data.data[key];
          }
          this.getListIrban(this.detail.level_irban);
          this.loading = false;
          this.createMap();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getIrban(event) {
      axios
        .get("/api/irban/" + event.target.value)
        .then((response) => {
          this.listIrban = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getListIrban(param) {
      axios
        .get("/api/irban/" + param)
        .then((response) => {
          this.listIrban = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    prosesLaporan(id, params) {
      this.loading = true;
      const formData = new FormData();
      formData.append("id_laporan", id);
      formData.append("status_laporan", params);
      formData.append("irban", this.detail.irban);
      formData.append("level_irban", this.detail.level_irban);
      for (let key in this.pelapor) {
        formData.append(key, this.pelapor[key]);
      }

      axios
        .post("/api/laporan/status", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.loading = false;
          this.fetchData();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    createMap() {
      const map = L.map("map").setView([this.detail.lat, this.detail.lng], 13);

      let g_street = "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}";

      const customIcon = L.icon({
        iconUrl: "https://cdn.demoku.web.id/marker.png",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
      });

      L.tileLayer(g_street, {
        attribution: "",
        maxZoom: 25,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }).addTo(map);
      const marker = L.marker(
        [this.detail.lat, this.detail.lng],
        { icon: customIcon } // Menggunakan ikon kustom
      ).addTo(map);

      marker.bindPopup("Titik Lokasi").openPopup();
    },
  },
};
</script>

<style>
/* Atur gaya peta di sini jika diperlukan */
#map {
  height: 100px;
  width: 100%;
}
.tables {
  width: 100%;
}
.tables tr td {
  padding: 10px;
}
.img-box {
  width: 100%;
  height: 350px;
  overflow: hidden;
}
.skeleton-screen {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton-loader {
  width: 100%;
  height: 20px;
  display: block;
  border-radius: 3px;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    #d3d3d3;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>