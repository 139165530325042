<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="card mb-4 mt-4">
            <div class="card-header">Form Pengguna</div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>NIK</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.nomor_identitas ? ' is-invalid' : ''"
                      name="nomor_identitas"
                      v-model="form.nomor_identitas"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.nomor_identitas"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Nama</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.nama_lengkap ? ' is-invalid' : ''"
                      name="nama_lengkap"
                      v-model="form.nama_lengkap"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.nama_lengkap"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Telp/WA</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.no_handphone ? ' is-invalid' : ''"
                      name="no_handphone"
                      v-model="form.no_handphone"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.no_handphone"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="formValidate.email ? ' is-invalid' : ''"
                      name="email"
                      v-model="form.email"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.email"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="formValidate.password ? ' is-invalid' : ''"
                      name="password"
                      v-model="form.password"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.password"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Ulangi Password</label>
                    <input
                      type="password"
                      class="form-control"
                      :class="formValidate.password_ulang ? ' is-invalid' : ''"
                      name="password_ulang"
                      v-model="form.password_ulang"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.password_ulang"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label>Level User</label>
                    <select
                      name="level"
                      class="form-select"
                      :class="formValidate.level ? ' is-invalid' : ''"
                      v-model="form.level"
                    >
                      <option value="admin">Admin</option>
                      <option value="user">IRBAN</option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.level"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2" v-if="form.level == 'user'">
                  <div class="form-group">
                    <label>Level IRBAN</label>
                    <select
                      name="level"
                      class="form-select"
                      :class="formValidate.level_irban ? ' is-invalid' : ''"
                      v-model="form.level_irban"
                    >
                      <option value="level_1">Level 1</option>
                      <option value="level_2">Level 2</option>
                      <option value="level_3">Level 3</option>
                      <option value="level_4">Level 4</option>
                      <option value="level_5">Level 5</option>
                      <option value="level_6">Level 6</option>
                      <option value="level_7">Level 7</option>
                      <option value="level_8">Level 8</option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.level_irban"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-sm btn-success float-end"
                @click="postData()"
                :disabled="this.loading"
              >
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "IndexPage",
  data() {
    return {
      form: {
        nama_lengkap: "",
        nomor_identitas: "",
        no_handphone: "",
        email: "",
        password: "",
        password_ulang: "",
        level: "",
        level_irban: "",
      },
      formValidate: [],
      loading: false,
    };
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("no_handphone", this.form.no_handphone);
      formData.append("email", this.form.email);
      formData.append("nomor_identitas", this.form.nomor_identitas);
      formData.append("password", this.form.password);
      formData.append("password_ulang", this.form.password_ulang);
      formData.append("level", this.form.level);
      formData.append("level_irban", this.form.level_irban);

      axios
        .post("/api/users/registrasi/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.push("/pengguna");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>