<template>
  <div id="blankLayout">
    <div id="blankLayout_content">
      <nav
        class="navbar sticky-top navbar-expand-lg py-md-3 px-md-3 navbar-dark bg-white"
        style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)"
      >
        <div class="container-md justify-content-md-centers">
          <img
            src="../../assets/logo-text.png"
            alt="Logo"
            height="50"
            class="d-inline-block align-text-top"
          />
          <router-link class="btn btn-outline-orange" to="/login"
            >Login
          </router-link>
        </div>
      </nav>
      <main>
        <div style="background-color: #f4f6f7; height: 103vh">
          <div
            class="col-md-12 mb-2 mx-auto p-3 py-md-5 text-center text-white home-bg"
          >
            <div class="col-md-6 mx-auto my-auto py-3">
              <h1 class="mb-3">
                Selamat Datang di Whistle Blowing System Kota Palu
              </h1>
              <p>
                Whistle Blowing System adalah aplikasi yang disediakan oleh
                Pemerintah Kota Palu bagi Anda yang memiliki informasi dan ingin
                melaporkan suatu perbuatan berindikasi pelanggaran yang terjadi
                di lingkungan Pemerintah Kota Palu.
              </p>
              <p>
                Anda tidak perlu khawatir terungkapnya identitas diri anda
                karena Pemerintah Kota Palu akan MERAHASIAKAN IDENTITAS DIRI
                ANDA sebagai whistleblower. Pemerintah Kota Palu menghargai
                informasi yang Anda laporkan. Fokus kami kepada materi informasi
                yang Anda Laporkan.
              </p>
            </div>
            <div
              class="col-lg-9 mx-auto p-2 py-md-5 bg-white rounded-4 text-black shadow-sm"
            >
              <span class="judul"
                >JUMLAH <span class="text-orange">LAPORAN</span></span
              >
              <div class="row mt-3">
                <div class="col-3">
                  <h3>{{ home.diterima_total }}</h3>
                  <p>Diterima</p>
                </div>
                <div class="col-3">
                  <h3>{{ home.verifikasi_total }}</h3>
                  <p>Verifikasi</p>
                </div>
                <div class="col-3">
                  <h3>{{ home.proses_total }}</h3>
                  <p>Proses</p>
                </div>
                <div class="col-3">
                  <h3>{{ home.selesai_total }}</h3>
                  <p>Selesai</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mb-2">
            <div class="col-lg-12 mx-auto p-4 py-md-5 text-center">
              <div class="col-md-10 mx-auto">
                <span class="judul"
                  >UNSUR <span class="text-orange">PENGADUAN</span></span
                >
                <div class="row mt-5">
                  <div class="col-md-4 mb-4">
                    <div
                      class="rounded-3 shadow-sm p-4"
                      style="background-color: #f4f6f7"
                    >
                      <img
                        src="../../assets/1-what.png"
                        style="height: 100px"
                      />
                      <h4 class="text-orange fw-bold mt-3">WHAT</h4>
                      <div style="min-height: 100px">
                        Yaitu apa perbuatan berindikasi Tindak Pidana
                        Korupsi/pelanggaran yang diketahui.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div
                      class="rounded-3 shadow-sm p-4"
                      style="background-color: #f4f6f7"
                    >
                      <img src="../../assets/2-who.png" style="height: 100px" />
                      <h4 class="text-orange fw-bold mt-3">WHO</h4>
                      <div style="min-height: 100px">
                        Yaitu siapa yang bertanggungjawab/terlibat dan terkait
                        dalam perbuatan tersebut.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div
                      class="rounded-3 shadow-sm p-4"
                      style="background-color: #f4f6f7"
                    >
                      <img
                        src="../../assets/3-where.png"
                        style="height: 100px"
                      />
                      <h4 class="text-orange fw-bold mt-3">WHERE</h4>
                      <div style="min-height: 100px">
                        Yaitu dimana tempat terjadinya perbuatan tersebut
                        dilakukan.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div
                      class="rounded-3 shadow-sm p-4"
                      style="background-color: #f4f6f7"
                    >
                      <img
                        src="../../assets/4-where.png"
                        style="height: 100px"
                      />
                      <h4 class="text-orange fw-bold mt-3">WHEN</h4>
                      <div style="min-height: 100px">
                        Yaitu kapan waktu perbuatan tersebut dilakukan.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div
                      class="rounded-3 shadow-sm p-4"
                      style="background-color: #f4f6f7"
                    >
                      <img src="../../assets/5-how.png" style="height: 100px" />
                      <h4 class="text-orange fw-bold mt-3">HOW</h4>
                      <div style="min-height: 100px">
                        Yaitu Bagaimana cara perbuatan tersebut dilakukan
                        (modus, cara, dan sebagainya).
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4">
                    <div
                      class="rounded-3 shadow-sm p-4"
                      style="background-color: #f4f6f7"
                    >
                      <img
                        src="../../assets/6-evidence.png"
                        style="height: 100px"
                      />
                      <h4 class="text-orange fw-bold mt-3">EVIDENCE</h4>
                      <div style="min-height: 100px">
                        Yaitu Dilengkapi dengan bukti permulaan (data, dokumen,
                        gambar dan rekaman) yang mendukung.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <main style="background-color: #f4f6f7">
        <div class="col-lg-9 mx-auto p-4 py-md-5">
          <div class="row">
            <span class="judul text-center"
              >BUAT <span class="text-orange">LAPORAN</span></span
            >
            <div class="col-md-12 mt-4">
              <div class="row mb-3">
                <div class="col-md-6 mb-1">
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-lg btn-success"
                      @click="openForm(1)"
                      type="button"
                    >
                      WBS (Whistle Blowing Sistem)
                    </button>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-lg btn-warning"
                      @click="openForm(2)"
                      type="button"
                    >
                      DUMAS (Pengaduan Masyarakat)
                    </button>
                  </div>
                </div>
              </div>
              <form
                @submit.prevent="postData"
                enctype="multipart/form-data"
                id="myForm"
              >
                <div v-if="setForm == 0">
                  <div class="alert alert-primary" role="alert">
                    Pilih Jenis Formulir
                    <ul>
                      <li>Pilih WBS jika anda merupakan ASN</li>
                      <li>Pilih DUMAS jika anda merupakan Warga Sipil</li>
                    </ul>
                  </div>
                </div>
                <div v-else class="card shadow-sm border-1 rounded-lg">
                  <div
                    class="card-header py-3"
                    :class="
                      setForm == 1 ? 'bg-success text-white' : 'bg-warning'
                    "
                  >
                    <strong class="text-center font-weight-light my-2">
                      Form {{ formType == 1 ? "Pelapor" : "Terlapor" }}
                      {{
                        setForm == 1
                          ? "WBS (Whistle Blowing Sistem)"
                          : "DUMAS (Pengaduan Masyarakat)"
                      }}
                    </strong>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-light float-end"
                      v-if="formType == 1"
                      @click="setForm = 0"
                    >
                      Batal
                    </button>
                  </div>
                  <div class="card-body" v-if="formType == 1">
                    <div class="mb-2 row" v-if="setForm == 1">
                      <label class="col-sm-3 col-form-label"
                        >Jenis Pelanggaran</label
                      >
                      <div class="col-sm-9">
                        <select
                          v-model="pelapor.jenis_pelanggaran"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.jenis_pelanggaran,
                          }"
                        >
                          <option value="">Pilih Jenis Pelanggaran</option>
                          <option value="Kode Etik">Kode Etik</option>
                          <option value="Korupsi">Korupsi</option>
                          <option value="Nepotisme">Nepotisme</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">NIK</label>
                      <div class="col-sm-9">
                        <div class="input-group">
                          <input
                            v-model="pelapor.nik_pelapor"
                            type="text"
                            minlength="16"
                            maxlength="16"
                            :class="{
                              ' is-invalid': formValidate.nik_pelapor,
                            }"
                            @input="
                              pelapor.nik_pelapor = pelapor.nik_pelapor.replace(
                                /[^0-9]/g,
                                ''
                              )
                            "
                            class="form-control"
                            aria-describedby="basic-addon2"
                          />
                          <button
                            type="button"
                            class="input-group-text"
                            id="basic-addon2"
                            @click="cekNIK()"
                          >
                            <i
                              v-if="loadingNIK"
                              class="fas fa-circle-notch fa-spin"
                              style="margin-right: 5px"
                            ></i>
                            Cek NIK
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="mb-2 row" v-if="setForm == 1">
                      <label class="col-sm-3 col-form-label">NIP</label>
                      <div class="col-sm-9">
                        <input
                          type="number"
                          v-model="pelapor.nip_pelapor"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.nip_pelapor,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label"
                        >Nama Pelapor</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="pelapor.nama_pelapor"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.nama_pelapor,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Pekerjaan</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="pelapor.pekerjaan"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.pekerjaan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Jabatan</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="pelapor.jabatan"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.jabatan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Alamat</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="pelapor.alamat"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.alamat,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Telp/WA</label>
                      <div class="col-sm-9">
                        <input
                          type="number"
                          v-model="pelapor.no_telp"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.no_telp,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Foto KTP</label>
                      <div class="col-sm-9">
                        <input
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.foto_ktp,
                          }"
                          type="file"
                          id="formFilePelapor"
                          @change="selectKtp"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.foto_ktp"
                        ></small>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" v-if="formType == 2">
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Institusi</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="terlapor.institusi"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.institusi,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label"
                        >Nama Terlapor</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="terlapor.nama_terlapor"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.nama_terlapor,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Jabatan</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="terlapor.jabatan_terlapor"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.jabatan_terlapor,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Pekerjaan</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="terlapor.pekerjaan_terlapor"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.pekerjaan_terlapor,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Alamat</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="terlapor.alamat_terlapor"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.alamat_terlapor,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Isi Aduan</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          v-model="terlapor.isi_aduan"
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.isi_aduan,
                          }"
                        />
                      </div>
                    </div>
                    <div class="mb-2 row">
                      <label class="col-sm-3 col-form-label">Bukti </label>
                      <div class="col-sm-9">
                        <input
                          class="form-control"
                          :class="{
                            ' is-invalid': formValidate.bukti_kejadian,
                          }"
                          type="file"
                          id="formFileTerlapor"
                          @change="selectBukti"
                        />
                        <small
                          class="text-danger"
                          v-html="formValidate.bukti_kejadian"
                        ></small>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-3">
                    <button
                      type="button"
                      class="btn btn-warning"
                      v-if="formType == 1"
                      @click="setForm = 0"
                    >
                      Tutup Form
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      v-if="formType == 2"
                      @click="formType = 1"
                    >
                      Kembali
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary float-end"
                      v-if="formType == 1"
                      v-on:click="validasiPelapor(2)"
                    >
                      <i
                        v-if="loading"
                        class="fas fa-circle-notch fa-spin"
                        style="margin-right: 5px"
                      ></i>
                      Selanjutnya
                    </button>
                    <button
                      v-on:click="postData()"
                      type="button"
                      class="btn btn-success float-end"
                      v-if="formType == 2"
                      :disabled="this.loading"
                    >
                      <i
                        v-if="loading"
                        class="fas fa-circle-notch fa-spin"
                        style="margin-right: 5px"
                      ></i>
                      Laporkan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <footer class="pt-5 my-5 px-5 text-body-secondary border-top">
        Developed by IT Palugate team &middot; &copy; 2023
      </footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "HomePage",
  data() {
    return {
      setForm: 0,
      formType: 1,
      pelapor: {
        jenis_pelapor: "",
        jenis_pelanggaran: "",
        nik_pelapor: "",
        nip_pelapor: "",
        nama_pelapor: "",
        alamat: "",
        pekerjaan: "",
        jabatan: "",
        no_telp: "",
      },
      terlapor: {
        institusi: "",
        nama_terlapor: "",
        pekerjaan_terlapor: "",
        jabatan_terlapor: "",
        alamat_terlapor: "",
        lng: "",
        lat: "",
        isi_aduan: "",
      },
      home: {
        diterima: 0,
        verifikasi: 0,
        proses: 0,
        selesai: 0,
      },
      formValidate: [],
      link: "/api/lapor/create",
      loading: false,
      loadingNIK: false,
      message: "",
      fileKtp: "",
      fileBukti: "",
    };
  },
  mounted() {
    this.fetchData();
    this.getLocation();
  },
  methods: {
    cekNIK() {
      if (this.pelapor.nik_pelapor == "") {
        this.formValidate = { nik_pelapor: " " };
      } else {
        this.loadingNIK = true;
        axios
          .get("/api/nik/" + this.pelapor.nik_pelapor)
          .then((response) => {
            console.log(response.data.status);
            if (response.data.status == true) {
              this.pelapor.nip_pelapor = response.data.data.nip_pelapor;
              this.pelapor.nama_pelapor = response.data.data.nama_pelapor;
              this.pelapor.pekerjaan = response.data.data.pekerjaan;
              this.pelapor.jabatan = response.data.data.jabatan;
              this.pelapor.alamat = response.data.data.alamat;
              this.pelapor.no_telp = response.data.data.no_telp;
              this.loadingNIK = false;
              this.formValidate = [];
            } else {
              for (let key in this.pelapor) {
                this.pelapor[key] = "";
              }
              this.loadingNIK = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    fetchData() {
      axios
        .get("/api/dashboard/")
        .then((response) => {
          this.home.diterima = response.data.data.diterima_total;
          this.home.verifikasi = response.data.data.verifikasi_total;
          this.home.proses = response.data.data.proses_total;
          this.home.selesai = response.data.data.selesai_total;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    selectKtp(event) {
      const file = event.target.files[0];
      this.fileKtp = file;
    },
    selectBukti(event) {
      const file = event.target.files[0];
      this.fileBukti = file;
    },
    validasiPelapor() {
      this.loading = true;
      const formData = new FormData();
      formData.append("jenis_laporan", this.setForm);
      formData.append("foto_ktp", this.fileKtp);
      for (let key in this.pelapor) {
        formData.append(key, this.pelapor[key]);
      }

      axios
        .post("/api/lapor/pelapor", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.formType = 2;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("jenis_laporan", this.setForm);
      formData.append("foto_ktp", this.fileKtp);
      formData.append("bukti_kejadian", this.fileBukti);
      for (let key in this.pelapor) {
        formData.append(key, this.pelapor[key]);
      }
      for (let key in this.terlapor) {
        formData.append(key, this.terlapor[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            Swal.fire({
              title: "Sukses",
              text: "Laporan Anda Berhasil Dikirim",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.loading = false;
              for (let key in this.pelapor) {
                this.pelapor[key] = "";
              }
              for (let key in this.terlapor) {
                this.terlapor[key] = "";
              }
              this.setForm = 0;
              this.formType = 1;
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        alert("Geolokasi tidak didukung di browser ini.");
      }
    },
    showPosition(position) {
      this.terlapor.lat = position.coords.latitude;
      this.terlapor.lng = position.coords.longitude;
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Akses ke lokasi ditolak oleh pengguna.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Informasi lokasi tidak tersedia.");
          break;
        case error.TIMEOUT:
          alert("Waktu permintaan lokasi habis.");
          break;
        case error.UNKNOWN_ERROR:
          alert("Terjadi kesalahan yang tidak diketahui.");
          break;
      }
    },
    openForm(params) {
      if (params == 1) {
        this.setForm = 1;
        this.formType = 1;
        this.resetForm();
      } else {
        this.setForm = 2;
        this.formType = 1;
        this.resetForm();
      }
    },
    resetForm() {
      this.pelapor.jenis_pelanggaran = "";
      this.pelapor.nik_pelapor = "";
      this.pelapor.nip_pelapor = "";
      this.pelapor.nama_pelapor = "";
      this.pelapor.alamat = "";
      this.pelapor.pekerjaan = "";
      this.pelapor.jabatan = "";
      this.pelapor.no_telp = "";
      this.terlapor.institusi = "";
      this.terlapor.nama_terlapor = "";
      this.terlapor.pekerjaan_terlapor = "";
      this.terlapor.jabatan_terlapor = "";
      this.terlapor.alamat_terlapor = "";
      this.terlapor.isi_aduan = "";
      this.formValidate = [];
    },
  },
};
</script>
<style scoped>
.home-bg {
  background-image: url("../../assets/wave.svg");
  background-size: cover;
  height: 90vh;
}
.navbar-toggler {
  background-color: #fd7e14;
  border: #fd7e14;
}
.navbar-toggler:active {
  background-color: #fd7e14;
  border: #fd7e14;
}
.bg-orange {
  background-color: #fd7e14;
}
.bg-orange-dark {
  background-color: #d16710;
}
.judul {
  font-size: 1.5em;
  font-weight: bold;
}
.text-orange {
  color: #d16710;
}
.btn-outline-orange {
  border-color: #d16710;
  border-style: dashed;
  padding: 15px 20px;
  text-transform: uppercase;
}
.btn-outline-orange:hover {
  background-color: #fd7e14;
  color: #fff;
}
</style>