var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"card mb-4 mt-4"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
              enabled: true,
              placeholder: 'Cari',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 9,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',[(props.row.status_laporan == 0)?_c('span',{staticClass:"badge bg-secondary"},[_vm._v("Laporan Masuk")]):_vm._e(),(props.row.status_laporan == 1)?_c('span',{staticClass:"badge bg-info"},[_vm._v("Verifikasi")]):_vm._e(),(props.row.status_laporan == 2)?_c('span',{staticClass:"badge bg-warning"},[_vm._v("Proses")]):_vm._e(),(props.row.status_laporan == 3)?_c('span',{staticClass:"badge bg-success"},[_vm._v("Selesai")]):_vm._e()]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"to":'/wbs/detail/' + props.row.id_laporan}},[_c('i',{staticClass:"fa fa-search"})]),(_vm.userLevel == 'admin')?_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_laporan)}}},[_c('i',{staticClass:"fa fa-trash-alt"})]):_vm._e()],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm btn-light",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-sync",class:{ 'fa-spin': _vm.loading }})]),_c('button',{staticClass:"btn btn-sm btn-success",staticStyle:{"margin-right":"5px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadExcel()}}},[_c('i',{staticClass:"fa fa-file-excel d-md-none"}),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Export Excel")])])])])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('i',{staticClass:"fas fa-table me-1"}),_vm._v(" WBS ")])
}]

export { render, staticRenderFns }