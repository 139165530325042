<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <h2 class="mt-4 mb-3">Dashboard</h2>
        <!-- <ol class="breadcrumb mb-4">
          <li class="breadcrumb-item active">Dashboard</li>
        </ol> -->
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <div class="card bg-danger text-white mb-4">
              <div class="card-body">
                Diterima
                <h4 class="mt-3">{{ home.diterima_total }}</h4>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card bg-warning text-white mb-4">
              <div class="card-body">
                Verifikasi
                <h4 class="mt-3">{{ home.verifikasi_total }}</h4>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card bg-primary text-white mb-4">
              <div class="card-body">
                Proses
                <h4 class="mt-3">{{ home.proses_total }}</h4>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6">
            <div class="card bg-success text-white mb-4">
              <div class="card-body">
                Selesai
                <h4 class="mt-3">{{ home.selesai_total }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div ref="barGrafik"></div>
          </div>
          <div class="col-md-6">
            <div ref="barInstitusi"></div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
import Highcharts from "highcharts";
export default {
  name: "DashboardPage",
  data() {
    return {
      home: {
        diterima_total: 0,
        verifikasi_total: 0,
        proses_total: 0,
        selesai_total: 0,
        diterima_wbs: 0,
        verifikasi_wbs: 0,
        proses_wbs: 0,
        selesai_wbs: 0,
        diterima_dumas: 0,
        verifikasi_dumas: 0,
        proses_dumas: 0,
        selesai_dumas: 0,
      },
      nama_institusi: [],
      jumlah_institusi: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/api/dashboard/")
        .then((response) => {
          this.home.diterima_total = response.data.data.diterima_total;
          this.home.verifikasi_total = response.data.data.verifikasi_total;
          this.home.proses_total = response.data.data.proses_total;
          this.home.selesai_total = response.data.data.selesai_total;
          this.home.diterima_wbs = response.data.data.diterima_wbs;
          this.home.verifikasi_wbs = response.data.data.verifikasi_wbs;
          this.home.proses_wbs = response.data.data.proses_wbs;
          this.home.selesai_wbs = response.data.data.selesai_wbs;
          this.home.diterima_dumas = response.data.data.diterima_dumas;
          this.home.verifikasi_dumas = response.data.data.verifikasi_dumas;
          this.home.proses_dumas = response.data.data.proses_dumas;
          this.home.selesai_dumas = response.data.data.selesai_dumas;
          this.nama_institusi = response.data.institusi.map(
            (item) => item.institusi
          );
          this.jumlah_institusi = response.data.institusi.map((item) =>
            parseInt(item.jumlah)
          );
          this.renderGrafik();
          this.renderGrafikInstitusi();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    renderGrafik() {
      Highcharts.chart(this.$refs.barGrafik, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Grafik Jumlah Laporan WBS dan Dumas",
        },
        xAxis: {
          categories: ["WBS", "Dumas"],
        },
        yAxis: {
          title: {
            text: "Jumlah",
          },
        },
        series: [
          {
            name: ["Diterima"],
            data: [
              parseInt(this.home.diterima_wbs),
              parseInt(this.home.diterima_dumas),
            ],
          },
          {
            name: "Verifikasi",
            data: [
              parseInt(this.home.verifikasi_wbs),
              parseInt(this.home.verifikasi_dumas),
            ],
          },
          {
            name: "Proses",
            data: [
              parseInt(this.home.proses_wbs),
              parseInt(this.home.proses_dumas),
            ],
          },
          {
            name: "Selesai",
            data: [
              parseInt(this.home.selesai_wbs),
              parseInt(this.home.selesai_dumas),
            ],
          },
        ],
      });
    },
    renderGrafikInstitusi() {
      Highcharts.chart(this.$refs.barInstitusi, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Grafik Jumlah Laporan Berdasarkan Institusi",
        },
        xAxis: {
          categories: this.nama_institusi,
        },
        yAxis: {
          title: {
            text: "Jumlah",
          },
        },
        series: [
          {
            name: "Jumlah",
            data: this.jumlah_institusi,
            colorByPoint: true,
          },
        ],
      });
    },
  },
};
</script>