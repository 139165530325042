var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"layoutAuthentication"}},[_c('div',{attrs:{"id":"layoutAuthentication_content"}},[_c('main',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"card shadow-lg border-0 rounded-lg mt-5"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"text-center font-weight-light my-2"},[_vm._v("Login")]),_c('form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('div',{staticClass:"form-floating mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",attrs:{"id":"inputEmail","type":"email","placeholder":"name@example.com"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}}),_c('label',{attrs:{"for":"inputEmail"}},[_vm._v("Email address")])]),_c('div',{staticClass:"form-floating mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",attrs:{"id":"inputPassword","type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('label',{attrs:{"for":"inputPassword"}},[_vm._v("Password")])]),(_vm.showAlert)?_c('div',{staticClass:"alert alert-dismissible fade show",class:{
                      '  alert-success': _vm.alert == 'success',
                      '  alert-danger': _vm.alert == 'error',
                    },attrs:{"role":"alert"}},[_c('span',[_vm._v(_vm._s(_vm.message))])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-4 mb-0"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":this.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" LOGIN ")])]),_c('div',{staticClass:"form-check mb-3"})])]),_vm._m(1)])])])])])]),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('img',{staticClass:"d-inline-block align-text-top",attrs:{"src":require("../assets/logo-text.png"),"alt":"Logo","height":"50"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-footer text-center py-3"},[_c('div',{staticClass:"small"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"layoutAuthentication_footer"}},[_c('footer',{staticClass:"py-4 bg-light mt-auto"},[_c('div',{staticClass:"container-fluid px-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between small"},[_c('div',{staticClass:"text-muted"},[_vm._v("Copyright © Inspektorat 2023")]),_c('div',[_c('a',{attrs:{"href":"#"}},[_vm._v("Privacy Policy")]),_vm._v(" · "),_c('a',{attrs:{"href":"#"}},[_vm._v("Terms & Conditions")])])])])])])
}]

export { render, staticRenderFns }