<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="row">
          <div class="col-md-12">NOT FOUND</div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "ForbiddenPage",
};
</script>