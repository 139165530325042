<template>
  <nav
    class="sb-topnav navbar navbar-expand navbar-light bg-white"
    style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)"
  >
    <router-link class="navbar-brand ps-3" to="/">
      <img
        src="../assets/logo-text.png"
        alt="Logo"
        height="40"
        class="d-inline-block align-text-top"
      />
    </router-link>
    <button
      class="btn btn-link btn-sm order-1 order-lg-0"
      id="sidebarToggle"
      @click="toggleSidebar"
    >
      <i class="fas fa-bars"></i>
    </button>
    <ul class="navbar-nav ms-auto me-0 me-md-3 my-2 my-md-0">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-toggle"
          id="navbarDropdown"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ><i class="fas fa-user fa-fw"></i
        ></a>
        <ul
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="navbarDropdown"
        >
          <li>
            <router-link
              :to="'/pengguna/update/' + this.nomor_identitas"
              class="dropdown-item"
            >
              Profil Saya
            </router-link>
          </li>
          <li>
            <router-link
              :to="'/pengguna/password/' + this.nomor_identitas"
              class="dropdown-item"
            >
              Ganti Password
            </router-link>
          </li>
          <!-- <li><a class="dropdown-item" href="javascript:;">Activity Log</a></li> -->
          <li><hr class="dropdown-divider" /></li>
          <li>
            <a class="dropdown-item" href="javascript:;" @click="logout()">
              Logout
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "NavBar",
  data() {
    return {
      nomor_identitas: "",
    };
  },
  created() {
    const user = localStorage.getItem("wbsData");
    if (user) {
      this.user = JSON.parse(user);
      this.nomor_identitas = this.user.nomor_identitas;
    }
  },
  methods: {
    logout() {
      Swal.fire({
        title: "Apakah Anda Akan Logout ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Logout!",
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("wbsData");
          if (this.$route.path !== "/login") {
            this.$router.push("/login");
          }
        }
      });
    },
    toggleSidebar() {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    },
  },
};
</script>