<template>
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="card shadow-lg border-0 rounded-lg mt-5">
                <div class="card-header">
                  <img
                    src="../assets/logo-text.png"
                    alt="Logo"
                    height="50"
                    class="d-inline-block align-text-top"
                  />
                </div>
                <div class="card-body">
                  <h3 class="text-center font-weight-light my-2">Login</h3>
                  <form @submit.prevent="login" method="POST">
                    <div class="form-floating mb-3">
                      <input
                        class="form-control"
                        v-model="username"
                        id="inputEmail"
                        type="email"
                        placeholder="name@example.com"
                      />
                      <label for="inputEmail">Email address</label>
                    </div>
                    <div class="form-floating mb-3">
                      <input
                        class="form-control"
                        v-model="password"
                        id="inputPassword"
                        type="password"
                        placeholder="Password"
                      />
                      <label for="inputPassword">Password</label>
                    </div>
                    <div
                      v-if="showAlert"
                      class="alert alert-dismissible fade show"
                      :class="{
                        '  alert-success': alert == 'success',
                        '  alert-danger': alert == 'error',
                      }"
                      role="alert"
                    >
                      <span>{{ message }}</span>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between mt-4 mb-0"
                    >
                      <!-- <a class="small" href="password.html">Forgot Password?</a> -->
                      <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        LOGIN
                      </button>
                      <!-- <router-link class="btn btn-primary" to="/dashboard"
                        >Login
                      </router-link> -->
                    </div>
                    <div class="form-check mb-3">
                      <!-- <input
                        class="form-check-input"
                        id="inputRememberPassword"
                        type="checkbox"
                        value=""
                      />
                      <label
                        class="form-check-label"
                        for="inputRememberPassword"
                        >Remember Password</label
                      > -->
                    </div>
                  </form>
                </div>
                <div class="card-footer text-center py-3">
                  <div class="small">
                    <!-- <router-link to="/registrasi"
                      >Need an account? Sign up!
                    </router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="layoutAuthentication_footer">
      <footer class="py-4 bg-light mt-auto">
        <div class="container-fluid px-4">
          <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">Copyright &copy; Inspektorat 2023</div>
            <div>
              <a href="#">Privacy Policy</a>
              &middot;
              <a href="#">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      showAlert: false,
      remember_me: false,
    };
  },
  methods: {
    login() {
      this.loading = true;
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("remember", this.remember_me);

      axios
        .post("/api/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.showAlert = true;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;
            setTimeout(() => {
              const wbsData = {
                nama: response.data.nama,
                email: response.data.email,
                level: response.data.level,
                nomor_identitas: response.data.nomor_identitas,
                token: response.data.token,
              };
              localStorage.setItem("wbsData", JSON.stringify(wbsData));
              this.showAlert = false;
              this.$router.push("/dashboard");
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.showAlert = true;
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
              this.showAlert = false;
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>