<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header">
            <i class="fas fa-table me-1"></i>
            DUMAS
          </div>
          <div class="card-body">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :lineNumbers="true"
              :search-options="{
                enabled: true,
                placeholder: 'Cari',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 9,
                perPageDropdownEnabled: false,
                position: 'bottom',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
              }"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-sm btn-light"
                    style="margin-right: 5px"
                  >
                    <i class="fa fa-sync" :class="{ 'fa-spin': loading }"></i>
                  </button>
                  <button
                    type="button"
                    v-on:click="downloadExcel()"
                    class="btn btn-sm btn-success"
                    style="margin-right: 5px"
                  >
                    <i class="fa fa-file-excel d-md-none"></i>
                    <span class="d-none d-md-block">Export Excel</span>
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <span
                    class="badge bg-secondary"
                    v-if="props.row.status_laporan == 0"
                    >Laporan Masuk</span
                  >
                  <span
                    class="badge bg-info"
                    v-if="props.row.status_laporan == 1"
                    >Verifikasi</span
                  >
                  <span
                    class="badge bg-warning"
                    v-if="props.row.status_laporan == 2"
                    >Proses</span
                  >
                  <span
                    class="badge bg-success"
                    v-if="props.row.status_laporan == 3"
                    >Selesai</span
                  >
                </span>
                <span v-if="props.column.field == 'action'">
                  <div>
                    <router-link
                      :to="'/dumas/detail/' + props.row.id_laporan"
                      class="btn btn-sm btn-success"
                      style="margin-right: 5px"
                    >
                      <i class="fa fa-search"></i>
                    </router-link>
                    <button
                      class="btn btn-sm btn-danger"
                      v-on:click="confirmDelete(props.row.id_laporan)"
                      v-if="userLevel == 'admin'"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
const ExcelJS = require("exceljs");

export default {
  name: "IndexPage",
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "tanggal",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Jam",
          field: "jam",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Pelapor",
          field: "nama_pelapor",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Terlapor",
          field: "nama_terlapor",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Institusi",
          field: "institusi",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Status",
          field: "status",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      tableInfo: "Tidak ada data yang dicari",
      loading: false,
      openForm: false,
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("wbsData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
    }
  },
  mounted() {
    this.fetchData();
    // this.loading = false;
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.tableInfo = "Memperbarui data";
      this.loading = true;
      axios
        .get("/api/dumas/")
        .then((response) => {
          this.rows = response.data.data;
          this.tableInfo = "Tidak ada data yang dicari";
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/dumas/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    downloadExcel() {
      axios
        .get("/api/dumas")
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item) => ({
            Tanggal: item.tanggal || " ",
            Jam: item.jam || " ",
            "Nama Pelapor": item.nama_pelapor || " ",
            "Nama Terlapor": item.nama_terlapor || " ",
            "Pekerjaan Terlapor": item.pekerjaan_terlapor || " ",
            "Jabatan Terlapor": item.jabatan_terlapor || " ",
            "Alamat Terlapor": item.alamat_terlapor || " ",
            "Isi Aduan": item.isi_aduan || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Data Dumas");

          const columnWidths = [
            { header: "Tanggal", key: "Tanggal", width: 18 },
            { header: "Jam", key: "Jam", width: 18 },
            { header: "Nama Pelapor", key: "Nama Pelapor", width: 30 },
            { header: "Nama Terlapor", key: "Nama Terlapor", width: 30 },
            {
              header: "Pekerjaan Terlapor",
              key: "Pekerjaan Terlapor",
              width: 30,
            },
            { header: "Jabatan Terlapor", key: "Jabatan Terlapor", width: 30 },
            { header: "Alamat Terlapor", key: "Alamat Terlapor", width: 30 },
            { header: "Isi Aduan", key: "Isi Aduan", width: 100 },
          ];

          worksheet.columns = columnWidths;

          // Mengisi data ke dalam worksheet seperti yang sudah Anda cantumkan
          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          // Mengatur header dengan font bold dan rata tengah (centered)
          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: "center" };
          });

          // Mengatur border pada sel-sel di worksheet
          const borderStyle = {
            style: "thin",
            color: { argb: "000000" }, // Hitam
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          // Mengkonversi workbook menjadi bentuk binary (buffer)
          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          // Mengunduh file Excel
          this.saveAsExcelFile(excelBuffer, "data-dumas.xlsx");
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        // Untuk Internet Explorer atau Edge
        navigator.msSaveBlob(blob, filename);
      } else {
        // Untuk browser modern
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
  },
};
</script>